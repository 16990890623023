.titleName {
  margin: 0 0 10px 10px;
}

.title {
  margin: 0;
}

.classifications_container {
  border: 1px solid black;
  padding: 10px;
}

.classElt {
  margin: 5px 0;
}
