.header_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header_child {
  display: grid;
  grid-template-columns: 25.5% 42% 12.5% 20%;
  width: 99%;
  border: 1px solid black;
  background-color: #d2d2d2;
  /* border-radius: 10px; */
  /* margin-top: 2px; */
  position: fixed;
  z-index: 100;
}
.header_body {
  margin-top: 100px;
}
