.navigate_cntnr {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #d2d2d2; */
  /* width:12%; */
  border-right: 1px solid black;
}
.navigate_child select {
  /* margin-right: 5px; */
  height: 35px;
  width: 150px;
}
