.PubContainer {
  margin-right: 10px;
  background-color: #f0f4f5;
}

.TableContainer {
  /* max-height: 100%; */
  /* overflow: scroll; */
  /* background-color: blueviolet; */
  max-height: 345px;
  overflow-y: scroll;
  border: 1px solid black;
}
.TableContainer table {
  /* width: 100%; */
  border-collapse: collapse;
}

.TableContainer tr td {
  padding: 25px;
  text-align: center;
  /* background-color: aqua; */
  /* border: 1px solid pink; */
  /* border-collapse: collapse; */
}

.TableContainer tr td:nth-child(1) {
  border: 1px solid black;
  border-left: 0;
}
.TableContainer tr td:nth-child(2) {
  border-right: 1px solid black;
}
.TableContainer tr td:nth-child(3) {
  border-right: 1px solid black;
}

.TableContainer thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #b1b1b1;
  padding: 50px;
}
.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: antiquewhite;
}

.Warning_text {
  background-color: #feeda7;
}

.Error_text {
  background-color: #ff8f8d;
}
