/* .gameTitle {
  display: flex;
} */

.gameDetails_container {
  /* background-color: blueviolet; */
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.gameDetails_title {
  text-align: center;
}

.gameDetails_flag {
  height: 20px;
  margin-left: 5%;
}
.formGrid {
  display: grid;
  grid-template-columns: 30% 59%;
  margin: 10px 0 10px 0;
  align-items: center;
}

.formGrid label {
  text-align: right;
  margin-right: 5%;
}

.formGrid input {
  border-radius: 10px;
  padding: 5px;
  border: 1px solid black;
}
.formGrid select {
  padding: 5px;
}

.gameDetails_update {
  display: flex;
  justify-content: center;
}

.gameDetails_update button {
  padding: 5px 10px;
  background-color: #e4e9e2;
  border: solid 1.5px black;
}
