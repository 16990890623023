/* .main_Dev_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mainDev_child {
  width: 99%;
  border: 3px solid black;
  border-radius: 10px;
  margin-top: 2px;
} */
.section {
  margin: 10px 0;
}
.mainContainer {
  margin: 10px;
}
.buttonText {
  border: none;
  background-color: #b1b1b1;
  /* padding: 13px; */
  /* margin: 5px 0; */
  font-size: 20px;
  text-decoration: underline;
}
.buttonTextExpanded {
  border: none;
  display: inline-block;
  width: fit-content;
  /* background-color: #b1b1b1; */
  padding: 13px;
  margin: 0px 0;
  font-size: 20px;
  text-decoration: underline;
}
.buttonTextCompressed {
  border: none;
  display: inline-block;
  width: fit-content;
  background-color: #b1b1b1;
  padding: 13px;
  margin: 0px 0;
  font-size: 20px;
  text-decoration: underline;
}
