.sectioncontainer {
  /* border: 1px solid black; */
  background-color: #cdd4dc;
}

.gridContainer {
  display: grid;
  margin: 10px 0;
  grid-template-columns: 60% 40%;
  padding: 10px;
  border: 1px solid black;
  /* border-radius: 10px; */
}

.gridContainer table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-radius: 10px;
  margin: 10px 0;
}

.gridContainer table thead {
  background-color: #c5e9ac;
}
.gridContainer th {
  border-top: 1px solid black;
}
.gridContainer thead th:nth-child(1) {
  border-bottom: 1px solid black;
}
.gridContainer thead th:nth-child(2) {
  border-bottom: 1px solid black;
}
.gridContainer thead th:nth-child(3) {
  border-bottom: 1px solid black;
}
.gridContainer thead th:nth-child(4) {
  border-bottom: 1px solid black;
}
.gridContainer thead th:nth-child(5) {
  border-bottom: 1px solid black;
}
.gridContainer tr td:nth-child(1) {
  border-left: 1px solid black;
}
.gridContainer th:nth-child(1) {
  border-left: 1px solid black;
}
.gridContainer tbody tr:nth-last-child(1) td:nth-child(1) {
  border-bottom: 1px solid black;
}
.gridContainer tbody tr:nth-last-child(1) td:nth-child(2) {
  border-bottom: 1px solid black;
}
.gridContainer tbody tr:nth-last-child(1) td:nth-child(3) {
  border-bottom: 1px solid black;
}
.gridContainer tbody tr:nth-last-child(1) td:nth-child(4) {
  border-bottom: 1px solid black;
}
.gridContainer tbody tr:nth-last-child(1) td:nth-child(5) {
  border-bottom: 1px solid black;
}
.gridContainer thead th,
tbody td {
  border-right: 1px solid black;
}

.tableContainer {
  /* border: 1px solid black; */
  /* background-color: blueviolet; */
}

.tableContainer tr td,
tr th {
  padding: 5px;
}

.tableContainer tr td:nth-last-child(2) {
  padding: 5px 0px 5px 2px;
}
.tableContainer tr td:nth-last-child(1) {
  padding: 5px 0;
}

.SectionBtns {
  width: 22px;
  border: none;
}

.sectionHeading {
  font-size: 25px;
  font-weight: 700;
}

.sectionClose {
  width: 23px;
  height: 23px;
  background: none;
  border: 1px solid black;
  font-weight: 600;
}

.SectionMid {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  /* background-color: blueviolet; */
}
.EditContainer {
  border: 1px solid black;
  padding: 10px;
  margin-left: 10px;
}
.EditContainer > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}
.gridForm {
  display: grid;
  grid-template-columns: 50% 50%;
}
.gridForm > div:nth-child(odd) {
  text-align: center;
  margin-bottom: 5px;
}
.SaveBtn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.SaveBtn button {
  padding: 5px;
}

.AddBtn {
  border: 1px solid black;
  padding: 5px 15px;
  font-size: 12px;
  background-color: #e5eae3;
  display: flex;
  align-items: center;
}

h5 {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.75px;
  /* background-color: red; */
}

.tableHeading {
  padding: 5px 0;
}

.summary {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  margin: 0;
  align-items: center;
  letter-spacing: 0.25px;
}
.filterImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* display: block; */
}
