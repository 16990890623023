.filter_cntnr {
  display: flex;
  align-items: center;
  border-right: 1px solid black;
}
.fltr_child {
  display: flex;
  width: 100%;
  justify-content: center;
}
.fltr_elems {
  display: flex;
  align-items: center;
  /* width: 90%; */
}
.fltr_elems select {
  margin-right: 5px;
  height: 35px;
  width: 190px;
}
.filterImg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.fltr_elems span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 35px;
  /* font-weight: bold; */
  /* font-size: large; */
}
.filter {
  background-color: #b1b1b1;
  border: none;
}
.fltr_elems small {
  color: blue;
  /* font-size: medium; */
}
.Clear {
  background: none;
  border: none;
}
