.search_cntnr {
  display: flex;
  align-items: center;
  width: 100%;
}
.search_child {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  grid-template-columns: 25% 55% 20%;
}
.search_text button {
  width: 100%;
  color: blue;
  font-size: 13px;
  background: none;
  border: none;
  /* margin-right: 15px; */
}
.search_input {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.search_input img {
  position: absolute;
  display: flex;
  margin-left: 5px;
}
.search_input input {
  font-size: 15px;
  height: 25px;
  width: 60%;
  padding-left: 35px;
}
.gop_logo {
  margin-left: -5px;
}
.gop_logo img {
  width: 80%;
  height: 80%;
}
