.GameName {
  /* background-color: #d2d2d2; */
  /* width:35%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
  /* letter-spacing: 1px; */
}
h1 {
  font-size: 25px;
  /* letter-spacing: 1px; */
}
