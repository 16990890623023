.pf_container {
  margin: 10px;
  /* background-color: blueviolet; */
}

h2 {
  font-size: 20px;
  margin: 0;
  letter-spacing: 0.5px;
  display: inline-block;
}

.pf_titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid black; */
}

.pf_names_container {
  width: 65%;
  margin: 10px 0;
}

.pf_names {
  align-items: center;
  margin: 0 5px 5px 0;
  display: inline-flex;
  align-items: center;
  background-color: #b1b1b1;
  border: none;
  padding: 3px;
}

.add_pf_btn {
  color: rgb(0, 142, 189);
  background-color: #d6d6d6;
  border: none;
  padding: 3px;
  text-decoration: wavy;
}

.delete_btn {
  margin: 0 0 0 5px;
}
