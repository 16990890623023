.pubsContainer {
  margin: 10px;
  /* height: 50%; */
}

.pubsHeader {
  display: flex;
  margin: 5px 0;
}

.refreshbtn {
  border: none;
  margin-left: 20px;
  background: none;
}
.pubsComponent {
  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: 75% 25%;
}

.pubsComponent:nth-child(1) {
  margin-right: 5%;
  background-color: blueviolet;
  /* justify-content: space-between; */
  /* grid-template-columns: 75% 25%; */
}

/* .pubs_right {
  background-color: blueviolet;
} */
